import Vue from "vue";
import App from "./App.vue";
import router from "./utils/router";
import Vuelidate from "vuelidate";
import moment from "moment";
import CxltToastr from "cxlt-vue2-toastr";
import { StripePlugin } from "@vue-stripe/vue-stripe";
import store from "./utils/store";

Vue.config.productionTip = false;
Vue.use(Vuelidate);
//toast with title and image
var toastrConfigs = {
  position: "top right",
  showDuration: 300,
  hideDuration: 300,
  timeOut: 5000,
};
Vue.use(CxltToastr, toastrConfigs);

const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  // stripeAccount: process.env.STRIPE_ACCOUNT,
  apiVersion: "2020-08-27",
  locale: "en",
};

setTimeout(() => {
  Vue.use(StripePlugin, options);
}, 2000);
Vue.prototype.$moment = moment;

import "./assets/revolution/css/layers.css";
// import "./assets/revolution/css/settings.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css";

store.commit("initialiseStore");
import "./utils/init";
import "./utils/filters.js";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
