<template>
    <div>
		<!-- Start Booking Steps Area -->
		<section class="booking-steps-area mht">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<ul class="booking-steps">
						    <li :class="{active: this.$route.name === 'vehicle'}">
						    	<span>1</span>	
						    	<div class="icon">
						    		<img src="/images/booking/car.png" alt="">				    		
						    	</div>
						    	<div class="text">
						    		CAR CLASS
						    	</div>
						    </li>
						    <li :class="{active: this.$route.name === 'bookingOptions'}">
						    	<span>2</span>	
						    	<div class="icon">
						    		<img src="/images/booking/options.png" alt="">
						    	</div>
						    	<div class="text">
						    		OPTIONS
						    	</div>
						    </li>
						    <li :class="{active: this.$route.name === 'cards'}">
						    	<span>3</span>	
						    	<div class="icon">
						    		<img src="/images/booking/card.png" alt="">
						    	</div>
						    	<div class="text">
						    		CREDIT CARD
						    	</div>
						    </li>
						    <li :class="{active: this.$route.name === 'checkout'}">
						    	<span>4</span>	
						    	<div class="icon">
						    		<img src="/images/booking/check-out.png" alt="">
						    	</div>
						    	<div class="text">
						    		CHECK OUT
						    	</div>
						    </li>
						</ul>
						
					</div>
				</div>
			</div>
		</section>
        <router-view></router-view>
    </div>
</template>

<script>

export default {
	
}
</script>

<style>

</style>