import Vue from 'vue';
import moment from 'moment';

Vue.filter('formatDate', function (date, format, local) { //MM/DD/YYYY hh:mm
    if (!format)
        format = 'MMM D, YYYY';

    let value = date ? (date.hasOwnProperty('value') ? date.value : date) : null;
    
    if (value) {
        //var utc = local ? value : moment.utc(value).toDate();
        //var local = local ? moment(utc).format() : moment(utc).local().format();
        //return moment(String(local)).format(format);
        return moment(value).format(format);
    }
});

Vue.filter('tripStatus', function (status) {
    const tripStatus = {
        FindingDrivers: 'New Request', 
        Completed: 'Completed', 
        Accepted: 'Accepted', 
        Rejected: 'Rejected', 
        Cancelled: 'Cancelled'
    }
    return tripStatus[status];
});