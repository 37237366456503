<template>
  <header id="header" :class="['header-01 fix_headerbk', customer.resetPassword ? 'headerDown' : '']">
    <div class="container-fuild">
      <div class="onclick" @click="activeHeaderMenu = !activeHeaderMenu">
        <span></span>
      </div>
      <div :class="['navigation', { active: activeHeaderMenu }]">
        <div
          id="main-menu"
          :class="['main-menu', { active: activeHeaderMenu }]"
        >
          <ul class="top-menuleft">
            <li class="x_close">
              <a
                href="javascript:void(0)"
                @click="activeHeaderMenu = !activeHeaderMenu"
                class="close_x"
                ><img src="/images/icon/close_x.png" alt=""
              /></a>
            </li>
            <li class="x_logo" @click="activeHeaderMenu = !activeHeaderMenu">
              <a href="https://allblacklimoseattle.com"
                ><img src="/images/logo.png" alt="" width="55"
              /></a>
            </li>
          </ul>
          <ul class="menu">
            <li class="has-dropdown">
              <a href="https://allblacklimoseattle.com"><span>Home</span></a>
            </li>
            <li>
              <a href="https://allblacklimoseattle.com/fleet"><span>Fleet</span></a>
            </li>
            <li>
              <a href="https://allblacklimoseattle.com/packages"
                ><span>Packages</span></a
              >
            </li>

            <li>
              <a href="https://allblacklimoseattle.com/contact"
                ><span>Contact Us</span></a
              >
            </li>
          </ul>
        </div>
      </div>
      <div id="logo" class="logo-pro">
        <a href="https://allblacklimoseattle.com" title="logo AOX Apps">
          <img src="images/logo.png" alt="" />
        </a>
      </div>
      <div class="box-right">
        <!-- <div class="search">
                    <a href="#" class="view_search"><i class="pe-7s-search"></i></a>
                    <form class="form-search" method="get" action="#">
                        <input class="action-text" type="text" name="s" placeholder="Type & Hit Enter...">
                        <button type="submit" id="submit"><i class="pe-7s-search"></i></button>
                        <a href="#" class="close-fixed" title="Close"><img src="images/icon/close_s.png" alt=""></a>
                    </form>
                </div> -->
        <div class="login">
          <router-link v-if="!isLoggedIn" :to="{ name: 'login' }"
            >Login/</router-link
          >
          <router-link v-if="!isLoggedIn" :to="{ name: 'login' }"
            >Register</router-link
          >
          <router-link
            v-if="isLoggedIn"
            :to="{ name: 'profile' }"
            class="login-menu"
            >Dashboard</router-link
          >
          <router-link v-if="isLoggedIn" :to="{ name: 'logout' }"
            >Logout</router-link
          >
        </div>
      </div>
    </div>
    <div v-if="customer.resetPassword" class="headerWarning">Warning: Reset Your Password!</div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      activeHeaderMenu: false,
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", "customer"]),
  },
  mounted() {
    // console.log(this.$route)
  },
  methods: {
    menuLink(params) {
      this.activeHeaderMenu = !this.activeHeaderMenu;
      this.$router.push(params);
    },
  },
};
</script>

<style scoped>
.login-menu {
  margin-right: 10px;
  border-right: 1px solid;
  padding-right: 10px;
}
.headerWarning{
      position: fixed;
    top: 0;
    text-align: center;
    left: 0;
    right: 0;
    background: #ec7211;
    color: #fff;
    text-shadow: #000 0px 1px 3px;
}
#header.headerDown{
  top: 30px !important;
}
</style>
