<template>
    <div id="wrapper">
        <!-- Sidebar -->
        <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
            <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'trips'}">
                    <i class="fa fa-bus"></i>
                    <span>Planned Trips</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'pastTrips'}">
                    <i class="fa fa-bus"></i>
                    <span>Completed Trips</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'user'}">
                    <i class="fa fa-user"></i>
                    <span>User Account</span>
                </router-link>
            </li>
        </ul>

        <!-- Content Wrapper -->
        <div id="content-wrapper" class="d-flex flex-column">

            <!-- Main Content -->
            <div id="content">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
@import '../../assets/css/sb-admin-2.min.css';

</style>