import Vue from 'vue';

const plugin = {
    install() {
        Vue.prototype.$limotoast = {
            success: (options) => {
                var toastMsg = titleSet(1, options);
                Vue.prototype.$toast.success(toastMsg);
            }, 
            error: (options) => {
                var toastMsg = titleSet(2, options);
                Vue.prototype.$toast.error(toastMsg);
            },
            info: (options) => {
                var toastMsg = titleSet(3, options);
                Vue.prototype.$toast.info(toastMsg);
            },
            warning: (options) => {
                var toastMsg = titleSet(4, options);
                Vue.prototype.$toast.warn(toastMsg);
            }
        };
        Vue.prototype.$disabledToday = (date) => {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < new Date(today.getTime() + 1 * 24 * 3600 * 1000);
        };
        Vue.prototype.$disabledBeforeToday = (date) => {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < new Date(today.getTime());
        };
        Vue.prototype.$scrollToTop = () => {
            $('html,body').stop().animate({
                scrollTop: 0
              }, 'slow', 'swing');
        };
        Vue.prototype.$kmToMiles = (km) => (km * 0.621371192).toFixed(2);
        Vue.prototype.$secondToMinutes = (seconds) => Math.round((seconds / 60) * 100) / 100;
        Vue.prototype.$getTimeDifferenceInMinute = (pickupTime, currentTime) => {
            var difference = 0;
            var startDateFormat = Vue.prototype.$moment(pickupTime);
            var endDateFormat = Vue.prototype.$moment(currentTime);
            difference = startDateFormat.diff(endDateFormat, 'minutes')
            difference = Math.round(difference);
            return difference;
        };
        Vue.prototype.$matchCarForDiscount = (string) => {
            const conditions = ["suv", "sedan", "ev"];
            return conditions.some(el => string.toLowerCase().includes(el));
        };
    }
};

Vue.use(plugin);

function titleSet(type, options) {
    var newObj = { title: options.title, message: options.message, type: options.type, useHtml: options.useHtml || false };
    if (options.title == undefined || !options.title) {
        switch (type) {
            case 1:
                newObj.title = 'Success!';
                break;
            case 2:
                newObj.title = 'Error!';
                break;
            case 3:
                newObj.title = 'Warning!';
                break;
            case 4:
                newObj.title = 'Info!';
                break;
        }
    }

    if (options.message == undefined) {
        newObj.message = options;
    } else {
        newObj.message = options.message
    }

    return newObj;
}