import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const persistedState = store => {
    // called when the store is initialized
    store.subscribe((mutation, state) => {
        if(Object.keys(state.customer).length){
            localStorage.setItem('store', JSON.stringify(state.customer));
        }
        if(Object.keys(state.booking).length){
            localStorage.setItem('booking', JSON.stringify(state.booking));
        }
        if(Object.keys(state.coupon).length){
            localStorage.setItem('coupon', JSON.stringify(state.coupon));
        }
        if(Object.keys(state.package).length){
            localStorage.setItem('package', JSON.stringify(state.package));
        }
        if(Object.keys(state.referral).length){
            localStorage.setItem('referral', JSON.stringify(state.referral));
        }
    })
  }

const store = new Vuex.Store({
    plugins: [persistedState],
    state: {
        customer: {},
        token: '',
        loader: false,
        booking: {},
        coupon: {},
        referral: {},
        package: {}

    },
    
    // actions are sync function used for get api request 
    actions: {
        async userLogout({ commit }){
            await commit('logout')
        }
    },
    // mutations are used to update the state
    mutations: {
        async initialiseStore(state) {
			if(localStorage.getItem('store')) {
                state.customer = JSON.parse(localStorage.getItem('store')) || {};
                
                // await this.replaceState(
				// 	Object.assign(state, JSON.parse(localStorage.getItem('store')))
				// );
			}
            if(localStorage.getItem('booking')) {
                state.booking = JSON.parse(localStorage.getItem('booking')) || {};
			}
            if(localStorage.getItem('coupon')) {
                state.coupon = JSON.parse(localStorage.getItem('coupon')) || {};
			}
            if(localStorage.getItem('package')) {
                state.package = JSON.parse(localStorage.getItem('package')) || {};
			}
            if(localStorage.getItem('referral')) {
                state.referral = JSON.parse(localStorage.getItem('referral')) || {};
			}
		},
        setUser(state, user) {
            state.customer = user;
            // state.token = user.token;
        },
        setSelectedCarType(state, car) {
            state.booking.carType = car;
        },
        setTripLocation(state, location) {
            state.booking.location = location;
        },
        setAdditionInfo(state, info) {
            state.booking.additionInfo = info;
        },
        setPayment(state, cardId) {
            state.booking.cardId = cardId;
        },
        setEstimatesDetails(state, details) {
            state.booking.estimates = details;
        },
        setCoupon(state, details) {
            state.coupon = details;
        },
        clearBooking(state){
            localStorage.removeItem('booking');
            localStorage.removeItem('coupon');
            localStorage.removeItem('package');
            localStorage.removeItem('referral');
            state.booking = {};
            state.coupon = {};
            state.package = {};
            state.referral = {};
        },
        logout(state){
            state.customer = {};
            state.booking = {};
            state.coupon = {};
            state.referral = {};
            state.token = '';
        },
        setLoader(state, flag){
            state.loader = flag;
        },
        updateHours(state, hours) {
            state.booking.location.selectedHour = hours;
        },
        setPackageDetails(state, form) {
            state.package = form;
        },
        addUserTripInPackage(state, trip) {
            state.package.userTrips = trip;
        },
        setReferral(state, details) {
            state.referral = details;
        },
    },
    // same as computed functions used for filter and calculation
    getters: {
        isLoggedIn: state => !!state.customer.token,
        userToken: state => state.customer.token,
        customer: state => state.customer,
        loader: state => state.loader,
        userId: state => state.customer._id,
        booking: state => state.booking,
        coupon: state => state.coupon,
        package: state => state.package,
        referral: state => state.referral,
    },
});

export default store;