<template>
  <div>
    <footer id="footer" class="footer-01">
      <div class="container">
        <div class="row">
          <div class="col-md-3 ft1">
            <div class="widget-footer widget-about">
              <div class="logo-ft">
                <a href="https://allblacklimoseattle.com" title="">
                  <img src="images/logo.png" alt="" width="65" />
                </a>
              </div>
              <ul class="infomation-ft">
                <li>All Black Limo LLC</li>
                <li>5800 Soundview dr A-104</li>
                <li>Gig Harbor, WA 98335</li>
                <li><a href="mailto:reservations@Allblacklimoseattle.com">reservations@Allblacklimoseattle.com</a></li>
                <li><a href="tel:877-206-0780">877-206-0780</a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-2 ft2">
            <div class="widget-footer widget-services">
              <h3 class="title-ft">Our services</h3>
              <ul>
                <li>
                  <a href="javascript:void(0)" @click="gotoService()">Corporate Service</a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="gotoService()">VIP Service</a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="gotoService()">Party Service</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-2 ft2">
            <div class="widget-footer widget-services">
              <h3 class="title-ft">About us</h3>
              <ul>
                <!-- <li>
                                    <a href="#" title="">Chauffeurs</a>
                                </li>
                                <li>
                                    <a href="#" title="">About us</a>
                                </li>
                                <li>
                                    <a href="#" title="">Blog</a>
                                </li>
                                <li>
                                    <a href="#" title="">FAQs</a>
                                </li>
                                <li>
                                    <a href="#" title="">Testimonials</a>
                                </li> -->
                <li>
                  <a href="https://booking.allblacklimoseattle.com/#/terms" title=""
                    >Terms & Service</a
                  >
                </li>
                <li>
                  <a href="https://booking.allblacklimoseattle.com/#/rental-agreement" title=""
                    >Rental Agreement</a
                  >
                </li>
                <li>
                  <a href="https://booking.allblacklimoseattle.com/#/contact" title=""
                    >Contact</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-3 ft2">
            <div class="widget-footer widget-services">
              <h3 class="title-ft">Social Links</h3>
              <ul class="social-ft">
                 <li>
                  <a href="https://www.facebook.com/ALLBLACKLIMO/" title="Facebook">
                    <i class="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/allblacklimo" title="Twitter">
                    <i class="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                 <a href="https://www.instagram.com/all_black_limo/?hl=en" title="Instagram">
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </li>

                <li>
                  <a href="https://www.linkedin.com/in/all-black-limo-54a52b257" title="Linkedin">
                    <i class="fa fa-linkedin" aria-hidden="true"></i>
                  </a>
                </li>

                <li>
                  <a href="https://www.youtube.com/channel/UCeCBc9Yn5r75gZeyeIzDpRA" title="Youtube">
                    <i class="fa fa-youtube" aria-hidden="true"></i>
                  </a>
                </li>
              <!--   <li>
                  <a href="#" title="Google">
                    <i class="fa fa-google" aria-hidden="true"></i>
                  </a>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- End Footer -->
    <!-- Start Footer Bottom -->
    <!-- <section class="footer-bottom bottom-01">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">Our Top Cities</div>
            <ul class="city">
              <li>
                <a href="#" title="">New York</a>
              </li>
              <li>
                <a href="#" title="">Istanbul</a>
              </li>
              <li>
                <a href="#" title="">London</a>
              </li>
              <li>
                <a href="#" title="">Berlin</a>
              </li>
              <li>
                <a href="#" title="">Los Angeles</a>
              </li>
              <li>
                <a href="#" title="">Paris</a>
              </li>
              <li>
                <a href="#" title="">All cities</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section> -->
    <!-- End Footer Bottom -->
    <!-- Start Copyright -->
    <section class="copyright cpr-01">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p>Copyright All Black Limo LLC © {{copyRight}}. All Rights Reserved</p>
          </div>
        </div>
      </div>
    </section>
    <!-- End Copyright -->
    <div class="scroll-top" @click="$scrollToTop()"></div>
  </div>
</template>

<script>
export default {
  computed:{
    copyRight(){
      return new Date().getFullYear();
    }
  },
  methods:{
    gotoService(){
      var element = document.getElementById("services");
      let top = element.offsetTop;
      window.scrollTo(0, top);
    }
  }
};
</script>

<style></style>
