import Vue from "vue";
import store from "./store.js";
import VueRouter from "vue-router";
import BookingLayout from "../views/booking/layout.vue";
import ProfileLayout from "../views/profile/layout.vue";
const routesObject = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/home"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "../views/forgotPassword.vue"
      ),
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    component: () =>
      import(
        /* webpackChunkName: "resetPassword" */ "../views/resetPassword.vue"
      ),
  },
  {
    path: "/logout",
    name: "logout",
    component: () =>
      import(/* webpackChunkName: "logout" */ "../views/logout.vue"),
  },
  {
    path: "/thank-you",
    name: "thanks",
    component: () =>
      import(/* webpackChunkName: "thankyou" */ "../views/thanks.vue"),
  },
  {
    path: "/contact",
    name: "contactus",
    component: () =>
      import(/* webpackChunkName: "contactus" */ "../views/contact.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    component: () =>
      import(/* webpackChunkName: "contactus" */ "../views/terms.vue"),
  },
  {
    path: "/rental-agreement",
    name: "rentalagreement",
    component: () =>
      import(
        /* webpackChunkName: "contactus" */ "../views/rentalAgreement.vue"
      ),
  },
  {
    path: "/fleet",
    name: "fleet",
    component: () =>
      import(/* webpackChunkName: "fleet" */ "../views/fleet.vue"),
  },
  {
    path: "/packages",
    props: true,
    // redirect: "/pac/vehicle",
    component: () => import(/* webpackChunkName: "packages" */ "../views/package/index.vue"),
    // name: "booking",
    children: [
      {
        path: "/",
        name: "packages",
        component: () =>
          import(/* webpackChunkName: "packages" */ "../views/package/packages.vue"),
      },
      {
        path: "payment",
        name: "package-payment",
        component: () =>
          import(/* webpackChunkName: "packages" */ "../views/package/payment.vue"),
      },
      {
        path: "overview",
        name: "package-overview",
        component: () =>
          import(/* webpackChunkName: "packages" */ "../views/package/overview.vue"),
      },
      {
        path: ":packageId",
        name: "package-details",
        component: () =>
          import(/* webpackChunkName: "packages" */ "../views/package/packageDetails.vue"),
      },
    ]
  },
  {
    path: "/booking",
    props: true,
    redirect: "/booking/vehicle",
    component: BookingLayout,
    name: "booking",
    children: [
      {
        path: "vehicle",
        props: true,
        name: "vehicle",
        component: () =>
          import(
            /* webpackChunkName: "vehicle" */ "../views/booking/vehicle.vue"
          ),
      },
      {
        path: "form",
        name: "bookingOptions",
        component: () =>
          import(
            /* webpackChunkName: "options" */ "../views/booking/options.vue"
          ),
      },
      {
        path: "payment",
        name: "cards",
        component: () =>
          import(
            /* webpackChunkName: "cards" */ "../views/booking/payment.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "checkout",
        name: "checkout",
        component: () =>
          import(
            /* webpackChunkName: "cards" */ "../views/booking/checkout.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },

  {
    path: "/profile",
    props: true,
    redirect: "/profile/upcoming-trips",
    component: ProfileLayout,
    name: "profile",
    children: [
      {
        path: "upcoming-trips",
        name: "trips",
        component: () =>
          import(/* webpackChunkName: "cards" */ "../views/profile/trips.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "past-trips",
        name: "pastTrips",
        component: () =>
          import(
            /* webpackChunkName: "cards" */ "../views/profile/pastTrips.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "tripDetail/:tripId",
        name: "tripDetail",
        component: () =>
          import(
            /* webpackChunkName: "cards" */ "../views/profile/tripDetails.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "editTripDetail/:tripId",
        name: "editTripDetail",
        component: () =>
          import(
            /* webpackChunkName: "cards" */ "../views/profile/editTripDetails.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "user",
        name: "user",
        component: () =>
          import(
            /* webpackChunkName: "userprofile" */ "../views/profile/userDetails.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

Vue.use(VueRouter);
const Route = new VueRouter({
  mode: "hash",
  routes: routesObject,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

Route.beforeEach((to, from, next) => {
  // set loader true
  // store.commit('setLoader', true);
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
  const currentUser = store.getters.isLoggedIn;
  if (currentUser && to.name === "Login") {
    next("/");
  }

  if (requiresAuth && !currentUser) {
    next("/login");
  } else {
    next();
  }
});

export default Route;
